export const systemCmdList = {
  clear: {
    type: 'system',
    label: 'System',
    content: '输入 "clear" 清除当前屏幕',
    aliasList: ['clear', 'cls']
  },
  help: {
    type: 'system',
    label: 'System',
    content: '输入 "help" 获得一个帮助列表',
    aliasList: ['help', 'ls']
  },
  exit: {
    type: 'system',
    label: 'System',
    content: '输入 "exit" 返回我的个人主页',
    aliasList: ['exit', 'back']
  },
  pwd: {
    type: 'system',
    label: 'System',
    content: '显示当前的目录位置',
    aliasList: ['pwd']
  },
  cd: {
    type: 'system',
    label: 'System',
    content: '改变当前的目录',
    aliasList: ['cd']
  },
  version: {
    type: 'system',
    label: 'System',
    content: '打印当前项目的版本',
    aliasList: ['version']
  }
}

export const tipCmdList = {
  jump: {
    type: 'system',
    label: 'System',
    content: '正在跳转页面...'
  },
  unknown: {
    type: 'error',
    label: 'Error',
    contentWithCommand: command => `命令 '${command}' 找不到`
  },
  error: {
    type: 'error',
    label: 'Error',
    content: '出错啦!'
  },
  supporting: '这是一个支持命令的列表'
}
